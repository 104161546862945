<template>
  <div>
    <div class="flex justify-end items-end pr-12">
      <!-- This button toggles the display of an informational section. -->
      <button
        @click="showInfo = !showInfo"
        class="flex items-center h-10 pt-10 pl-10 z-50 pb-4 chevy"
      >
        <!-- If "showInfo" is false, display the "Click for more info" text. -->
        <span
          v-if="!showInfo"
          class="font-Poppins text-xs font-extralight pr-2 animate-fade-in"
          >Click for more info</span
        >

        <!-- If "showInfo" is true, display the "Close" text with potential animation. -->
        <span
          v-if="showInfo"
          class="font-Poppins text-xs font-extralight pr-2"
          :class="animationClass"
          >Close</span
        >

        <!-- Chevron icon to visually indicate the toggle state. rotates depending on showInfo value -->
        <svg
          class="text-slate-400 fill-current h-4 w-4"
          :class="{ 'rotate-180': showInfo, animationClass }"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path
            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
          />
        </svg>
      </button>
    </div>

    <!-- The main container for the informational content. Depending on "animationClass", it may have some animations applied. -->
    <div
      class="flex flex-col mx-auto flex-shrink justify-center max-w-[1090px] bg-white pb-5 rounded-lg overflow-hidden"
      :class="animationClass"
    >
      <!-- This container adjusts its height based on the "showInfo" state, essentially revealing or hiding the information. -->
      <div
        v-bind:class="{ expand: showInfo, collapse: !showInfo }"
        class="mt-4 self-center overflow-hidden transition-max-height duration-1000 ease-in-out"
      >
        <!-- Content styling and padding wrapper. -->
        <div
          class="py-4 font-Poppins mx-auto my-auto pl-10 pr-10 duration-1000 ease-in-out"
        >
          <!-- Static heading for the info content. -->
          <div class="pb-2">
            <strong class="font-Poppins"
              >What are you being shown you here?</strong
            >
          </div>

          <!-- A slot allowing the parent component to inject its own custom content. -->
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Importing necessary reactive utilities from Vue.
import { ref, computed } from "vue";

// A reactive reference to control the display of information.
const showInfo = ref(false);

// A computed property that returns appropriate animation classes based on the `showInfo` state.
const animationClass = computed(() => {
  return showInfo.value ? "animate-fade-in" : "animate-fade-out";
});
</script>

<style scoped>
/* Styles for expanding the content container. */
.expand {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

/* Styles for collapsing the content container. */
.collapse {
  max-height: 0;
  transition: max-height 1s ease-in-out;
}

/* Animation for fading in the content. */
.animate-fade-in {
  animation: fade-in 1s ease-in-out forwards;
}

/* Animation for fading out the content. */
.animate-fade-out {
  animation: fade-out 1s ease-in-out forwards;
}

/* Keyframes for the "fade-in" animation. */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Keyframes for the "fade-out" animation. */
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
