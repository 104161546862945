<template>
  <div>
    <div class="py-2 ml-4 font-Poppins text-dbs-light-black">
      <span><strong>Seasonal Trend Consumption</strong></span>
    </div>
    <VueApexCharts
      :options="seasonalChart.options"
      :series="props.seriesData"
      height="300"
      width="1030"
    ></VueApexCharts>
  </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";

const emit = defineEmits(["no-data"]);

watch(
  () => props.seriesData,
  (newVal) => {
    if (!newVal.length) {
      emit("no-data");
    }
  },
  { immediate: true },
);

const props = defineProps({
  seriesData: {
    type: Array,
    required: false,
    default: () => [],
  },
  // categories: {
  //   type: Array,
  //   required: false,
  // },
});

// * Bar Chart data
const seasonalChart = reactive({
  series: [
    {
      name: "Daily Total",
      data: [
        Math.floor(Math.random() * (300 + 1) + 100),
        Math.floor(Math.random() * (300 + 1) + 100),
        Math.floor(Math.random() * (300 + 1) + 100),
        Math.floor(Math.random() * (300 + 1) + 100),
      ],
    },
  ],

  options: {
    chart: {
      type: "area",
      toolbar: {
        show: true,
      },
    },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
    colors: [
      "#6DAFB9",
      "#0c1e36",
      "#B99D64",
      "#45676D",
      "#372D6C",
      "#6455B6",
      "#4AB0A6",
      "#AB4A46",
      "#F67570",
      "#F8B14B",
      "#DCE1DE",
      "#393B3C",
      "#BA7A75",
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.25,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    // labels: ['Dec 01', 'Dec 02', 'Dec 03', 'Dec 04', 'Dec 05', 'Dec 06', 'Dec 07', 'Dec 08', 'Dec 09 ', 'Dec 10', 'Dec 11'],
    markers: {
      size: 0,
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return val + " kWh";
        },
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 900,
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          chart: {
            width: 1030,
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 470,
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 1040,
        options: {
          chart: {
            width: 700,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
    },
  },
});
</script>
