<template>
  <div>
    <div class="py-2 ml-4 font-Poppins text-dbs-light-black">
      <span><strong>Monthly kWh Consumption</strong></span>
    </div>
    <VueApexCharts
      ref="chart"
      :options="barChart.options"
      :series="props.seriesData"
      height="300"
      width="1030"
    >
    </VueApexCharts>
  </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";

const props = defineProps({
  seriesData: {
    type: Array,
    required: true,
  },

  categories: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(["no-data"]);

watch(
  () => props.seriesData,
  (newVal) => {
    if (!newVal.length) {
      emit("no-data");
    }
  },
  { immediate: true },
);

// * Bar Chart data
const barChart = reactive({
  options: {
    colors: [
      "#6DAFB9",
      "#0c1e36",
      "#B99D64",
      "#45676D",
      "#372D6C",
      "#6455B6",
      "#4AB0A6",
      "#AB4A46",
      "#F67570",
      "#F8B14B",
      "#DCE1DE",
      "#393B3C",
      "#BA7A75",
    ],

    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
      height: 300,
      width: 1030,
    },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },

    bar: {
      borderRadius: 10,
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },

    dataLabels: {
      enabled: false,
      style: {
        fontSize: "12px",
        colors: ["#0c1e36"],
      },
    },

    xaxis: {
      position: "bottom",
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.6,
            opacityTo: 0.7,
          },
        },
      },
    },

    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val + " kWh";
        },
      },
    },

    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 900,
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          chart: {
            width: 1030,
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 470,
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 1040,
        options: {
          chart: {
            width: 700,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  },
});
</script>
