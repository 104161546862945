import { defineStore } from "pinia";
import api from "../api/portal.ts";

export const useAuditStore = defineStore("audit", {
  state: () => ({
    selectedMeters: [{}],
    selectedSharedMeters: [],
    isLoading: false,
    isMeterDataLoading: false,
    isMeterListLoading: false,
    isInitialLoad: true,
    isOpen: false,
    selectedYear: "Last 12 Month",
    selectedMeter: "",
    customerMeters: [],
    meterData: {
      years: [],
      customer: {
        businessname: "",
        mpan: "",
      },
      monthlyKWHConsumption: {},
      timeOfDayConsumption: {},
      halfHourChart: {},
      seasonalTrendChart: {},
      totalPerDayChart: {},
      totalPerDayMinMax: {},
      timeOfDayHeatmap: {},
      timeOfMonthHeatmap: {},
    },
    meterList: [],
    sharedMeters: {
      meters: [{}],
      relatedReports: [{}],
    },
  }),

  getters: {},

  actions: {
    // Set the selected meters.
    setSelectedMeters(meters) {
      this.selectedMeters = meters;
    },

    // Set the selected shared meters.
    setSelectedSharedMeters(sharedMeters) {
      this.selectedSharedMeters = sharedMeters;
    },

    // Collect the list of meters associated with a given key.
    async collectMeterList(key) {
      try {
        this.isMeterListLoading = true;
        const { data } = await api.get(`/v1/share/${key}`);
        this.meterList = data;
      } catch (error) {
        console.log(error);
      }
      this.isMeterListLoading = false;
    },

    // Collect the list of meters for a user.
    async collectMeterListUser() {
      try {
        this.isMeterListLoading = true;
        const { data } = await api.get(`/v1/report/`);
        this.meterList = data;
      } catch (error) {
        console.log(error);
      }
      this.isMeterListLoading = false;
    },

    // Collect meter data for a specific zeusId for a user.
    async collectMeterDataUser(zeusId) {
      try {
        this.isMeterDataLoading = true;
        const { data } = await api.get(`/v1/report/${zeusId}`);
        this.meterData = data;
      } catch (error) {
        console.log(error);
      }
      this.isMeterDataLoading = false;
      if (this.isInitialLoad) {
        this.isInitialLoad = false;
      }
    },

    // Collect meter data for a specific key and zeusId.
    async collectMeterData(key, zeusId) {
      try {
        this.isMeterDataLoading = true;
        const { data } = await api.get(`/v1/share/${key}/${zeusId}`);
        this.meterData = data;
      } catch (error) {
        console.log(error);
      }
      this.isMeterDataLoading = false;
      if (this.isInitialLoad) {
        this.isInitialLoad = false;
      }
    },

    // Collect shared reports.
    async collectSharedReports() {
      try {
        const { data } = await api.get(`/v1/share`);
        this.sharedMeters = data;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },

    // Remove a meter from the selected shared meters by wordId.
    removeFromSelectedSharedMeters(wordId) {
      const index = this.selectedSharedMeters.findIndex(
        (meter) => meter === wordId,
      );
      if (index !== -1) {
        this.selectedSharedMeters.splice(index, 1);
      }
    },

    // Open a UI element or modal.
    open() {
      this.isOpen = true;
    },

    // Close a UI element or modal.
    close() {
      this.isOpen = false;
    },
  },
});
