<template>
  <!-- Root container for the dropdown component -->
  <div ref="dropdown">
    <!-- Button to toggle the dropdown menu visibility -->
    <button
      @click="show = !show"
      class="flex justify-between items-center text-dbs-faded-grey font-Poppins bg-white font-normal py-2 px-4 whitespace-no-wrap rounded transition duration-300 w-[300px] sm:w-[350px]"
    >
      <!-- Display selected meter ID or default value -->
      <div class="flex items-center">
        <span :class="{ 'text-dbs-green2': selectedMeter !== 'Select meter' }">
          {{
            selectedMeter.meterId ||
            (store.meterList.length
              ? store.meterList[0].meterId
              : "No meters available")
          }}
        </span>

        <!-- Static electricity bolt icon next to meter ID -->
        <i :class="boltClass">⚡</i>
      </div>

      <!-- Chevron icon indicating dropdown state. Rotates based on dropdown visibility -->
      <svg
        class="text-slate-400 fill-current chevy"
        :class="{ 'rotate-180': show }"
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path
          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
        />
      </svg>
    </button>

    <!-- Animated dropdown list container -->
    <transition :name="animation">
      <!-- Actual dropdown content; shown only if 'show' is true -->
      <div
        style="cursor: pointer"
        class="text-dbs-faded-grey mt-1 font-Poppins rounded bg-white absolute z-20 shadow-2xl w-[300px] sm:w-[350px]"
        v-if="show"
      >
        <!-- List of meters -->
        <ul
          class="list-none overflow-y-auto rounded max-h-96"
          id="shittyMeterList"
        >
          <!-- Loop through each meter in 'meters' array -->
          <li
            v-for="(meter, index) in meters"
            :key="index"
            :class="{ 'text-dbs-green2': meter === selectedMeter }"
          >
            <!-- Clickable link for each meter -->
            <a
              @click="selectMeter(meter), newMeter()"
              class="flex py-2 px-4 transition duration-300"
            >
              <!-- Static electricity bolt icon followed by meter ID -->
              <span class="mr-2">⚡</span> {{ meter.meterId }}
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
// Importing required Vue functionalities.
import { ref, onMounted, onUnmounted, watch, computed} from "vue";

// Importing store and route utilities.
import { useAuditStore } from "@/store/index";
import { useRoute } from "vue-router";

// Get the current route object.
const route = useRoute();

// References to manipulate the dropdown and its associated data.
const dropdown = ref(null);
const store = useAuditStore();
const meters = store.meterList;
const selectedMeter = ref("Select meter");
const show = ref(false);

// Flag to determine bouncing animation state.
const isBouncing = ref(false);

// Determine the class for the bolt icon based on several conditions.
const boltClass = computed(() => {
  if (selectedMeter.value) {
    if (isBouncing.value) {
      return store.isMeterDataLoading
        ? "ml-1 bounce-continuous"
        : "ml-1 bounce-once";
    } else {
      return "ml-1";
    }
  }
  return "";
});

// Method to fetch new meter data.
const newMeter = async () => {
  isBouncing.value = true;

  // Fetching data based on the route name.
  if (route.name === "Meter Audit") {
    await store.collectMeterDataUser(selectedMeter.value.zeusId);
    
  } else if (route.name === "Meter Audit Share") {
    await store.collectMeterData(
      route.params.word_id,
      selectedMeter.value.zeusId,
      
    );
  }

  // Timeout to update bouncing state after fetching data.
  setTimeout(() => {
    if (!store.isMeterDataLoading) {
      isBouncing.value = false;
    }
  }, 500);
};

// Watch the data loading state to update the bouncing animation.
watch(
  () => store.isMeterDataLoading,
  (newValue) => {
    if (!newValue) {
      isBouncing.value = false;
    }
  },
);

// Method to handle meter selection.
const selectMeter = (meter) => {
  selectedMeter.value = meter;
  show.value = false;
  store.selectedMeter = meter;
};

// Method to close dropdown on outside click.
const handleClickOutside = (event) => {
  if (!dropdown.value.contains(event.target)) {
    show.value = false;
  }
};

// Attach outside click listener after the component is mounted.
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

// Remove outside click listener when the component is destroyed.
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

// Define props for this component.
// eslint-disable-next-line no-undef
defineProps({
  animation: String,
});
defineExpose({
  selectMeter,
  newMeter,
  meters,
  selectedMeter,
  show,
  boltClass,
});
</script>

<style scoped>
/* Button styles */
button {
  cursor: pointer;

  /* Remove default outline on focus for custom styling */
  &:focus {
    outline: none;
  }
}

/* Animation classes for left slide effect */
.slide-in-left-enter-from,
.slide-in-left-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}
.slide-in-left-enter-to,
.slide-in-left-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.slide-in-left-enter-active,
.slide-in-left-leave-active {
  transition: all 0.5s;
}

/* Fade animation classes */
.fade-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.v-leave-to {
  opacity: 0;
}

/* Animation classes for upward slide effect */
.slide-in-up-enter-active,
.slide-in-up-leave-active {
  transition: all 0.5s;
  transform: translateY(0);
}
.slide-in-up-enter-from,
.slide-in-up-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
/* Keyframes for bouncing animations */
@keyframes bounceOnce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

@keyframes bounceContinuous {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Classes that use the defined keyframes for bouncing effects */
.bounce-once {
  animation: bounceOnce 0.5s forwards;
}
.bounce-continuous {
  animation: bounceContinuous 1s infinite;
}
</style>
