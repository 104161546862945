<template>
  <div>
    <VueApexCharts
      :options="pieChart.options"
      :series="props.seriesData"
      width="900"
      height="400"
    ></VueApexCharts>
  </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";

const emit = defineEmits(["no-data"]);

watch(
  () => props.seriesData,
  (newVal) => {
    if (!newVal.length) {
      emit("no-data");
    }
  },
  { immediate: true },
);

const props = defineProps({
  seriesData: {
    type: Array,
    required: false,
  },
});

// * Pie Chart data
const pieChart = reactive({
  options: {
    chart: {
      type: "pie",
    },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    colors: [
      "#6DAFB9",
      "#0c1e36",
      "#B99D64",
      "#45676D",
      "#372D6C",
      "#6455B6",
      "#4AB0A6",
      "#AB4A46",
      "#F67570",
      "#F8B14B",
      "#DCE1DE",
      "#393B3C",
      "#BA7A75",
    ],
    toolbar: {
      show: false,
    },
    legend: {
      position: "bottom",
    },
    labels: [
      "00:00 - 04:00",
      "04:00 - 08:00",
      "08:00 - 12:00",
      "12:00 - 16:00",
      "16:00 - 20:00",
      "20:00 - 00:00",
    ],
    responsive: [
      {
        breakpoint: 800,
        options: {
          chart: {
            width: "280",
            height: "347",
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          chart: {
            width: "900",
            height: "400",
          },
          legend: {
            show: true,
            offsetY: 8,
          },
        },
      },
    ],
  },
});
</script>
