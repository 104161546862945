import { loginRequest, msalInstance } from "../authConfig";

export function registerGuard(router) {
  router.beforeEach(async (to) => {
    if (to.meta.requiresAuth) {
      let redirectStartPage = to.fullPath;
      const isAuthenticated = checkAuthenticated(msalInstance);

      // Special path for linking to login from external site. Not intended to be accessed.
      if (to.fullPath === "/externallogin") {
        if (isAuthenticated) {
          return "/";
        }
        redirectStartPage = "/";
      }

      const request = { ...loginRequest, redirectStartPage };
      if (!isAuthenticated) {
        const redirect = await redirectLogin(msalInstance, request);
        return redirect || "/error";
      }
      return true;
    }
    return true;
  });
}

export async function redirectLogin(instance, request) {
  try {
    await instance.handleRedirectPromise();
    await instance.loginRedirect(request);
    return true;
  } catch (error) {
    return false;
  }
}

export function checkAuthenticated(instance) {
  const accounts = instance.getAllAccounts();
  return accounts.length > 0;
}
