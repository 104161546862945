import axios from "axios";
import { loginRequest, msalInstance } from "@/authConfig";
import router from "@/router";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

let baseURL = "https://ukdbg-insight-backend.azurewebsites.net/";
if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:7777/";
}

const api = axios.create({
  baseURL,
  timeout: 100000,
});

api.interceptors.request.use(
  async (config) => {
    if (router.currentRoute.value.meta.requiresAuth) {
      try {
        const res = await msalInstance.acquireTokenSilent(loginRequest);
        const token = res.accessToken;
        config.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.loginRedirect(loginRequest);
          return Promise.reject(error);
        } else {
          console.error(error);
          return Promise.reject(error);
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
