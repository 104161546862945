<template>
  <!-- Fade transition for the modal -->
  <transition name="fade" appear class="share-modal">
    <!-- Modal container. Only shown if props.isOpen is true -->
    <div
      v-if="isOpen"
      class="fixed inset-0 flex items-center justify-center z-[99] bg-black bg-opacity-50"
    >
      <!-- Nested fade transition for the loading overlay -->
      <transition name="fade">
        <!-- Loading overlay. Shown if isLoading is true -->
        <div
          v-if="isLoading"
          class="absolute inset-0 flex items-center justify-center z-[100]"
        >
          <!-- A semi-transparent background overlay to make the loader more visually pronounced -->
          <div
            class="absolute w-full h-full rounded-lg bg-gray-500 opacity-50"
          ></div>

          <!-- Container for the loader and loading text -->
          <div class="loader">
            <!-- spinner for loading indication. -->
            <!-- <div class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-dbs-green rounded-full" role="status" aria-label="loading">
              <span class="sr-only">Loading...</span>
            </div> -->

            <!-- "Working..." message during loading. -->
            <!-- <div class="text-white font-Poppins">Working...</div> -->
          </div>
        </div>
      </transition>

      <!-- Main modal content container -->
      <div
        class="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[600px] relative flex flex-col justify-start items-center"
      >
        <!-- Container for the top left content, which provides context about the modal's function -->
        <div class="top-6 left-6 space-x-2 p-4 font-Poppins">
          <!-- This transition toggles between two messages based on whether the archive is open or not -->
          <transition name="slide-up" mode="out-in">
            <!-- Message displayed when isArchiveOpen is false. It instructs users to select meters to share. -->
            <span v-if="!isArchiveOpen">
              <span class="font-Poppins">
                <span class="font-semibold">Select meters</span> to share with
                <span class="font-semibold text-dbs-green"
                  >third party users...</span
                >
              </span>
            </span>

            <!-- Message displayed when isArchiveOpen is true. It seems to instruct users to select meters to stop sharing. -->
            <span v-else-if="isArchiveOpen">
              <span class="font-Poppins">
                <span class="font-semibold">Select meters</span> to STOP sharing
                with
                <span class="font-semibold text-dbs-green"
                  >third party users...</span
                >
              </span>
            </span>
          </transition>
        </div>

        <!-- This container is set up to hold a table of items with horizontal scrolling and some constraints on its visual size -->
        <div
          class="relative flex overflow-x-hidden w-full justify-start sm:rounded-lg max-h-[400px]"
        >
          <!-- This transition animates the appearance/disappearance of the enclosed table with a slide-up effect -->
          <transition name="slide-up" mode="out-in">
            <!-- This table is only shown if the isArchiveOpen property is true. The table structure displays shared meters or reports -->
            <table
              v-if="isArchiveOpen"
              class="text-sm text-left text-gray-500 w-[600px]"
            >
              <!-- The table header, used to label the columns -->
              <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <!-- This column provides a checkbox for selecting all items in the table -->
                  <th scope="col" class="p-4">
                    <div class="flex justify-start">
                      <input
                        id="checkbox-all-shared"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-dbs-green focus:ring-2"
                        :checked="isAllSharedSelected"
                        @click="toggleAllSharedMeters"
                      />
                      <label for="checkbox-all-shared" class="sr-only"
                        >checkbox</label
                      >
                    </div>
                  </th>
                  <!-- The "Report" column header -->
                  <th scope="col" class="px-6 py-3">Report</th>
                </tr>
              </thead>

              <!-- The table body, which contains rows of data. Each row represents a shared report or meter -->
              <tbody>
                <tr
                  class="bg-white border-b hover:bg-gray-50 group relative cursor-pointer"
                  @mousemove="
                    setCurrentMeterIdAndPosition(report.wordId, $event)
                  "
                  v-for="(report, index) in sharedMeters.relatedReports"
                  :key="index"
                >
                  <!-- This column provides a checkbox for selecting the individual item -->
                  <td class="w-4 p-4">
                    <div class="flex items-center">
                      <input
                        id="checkbox-table-1"
                        type="checkbox"
                        class="w-4 h-4"
                        :value="report.wordId"
                        v-model="selectedSharedMeters"
                      />
                      <label for="checkbox-table-1" class="sr-only"
                        >checkbox</label
                      >
                    </div>
                  </td>
                  <!-- This column displays the ID (or some sort of unique identifier) of the report -->
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap relative"
                  >
                    <span class="mr-2">📜</span> {{ report.wordId }}

                    <!-- This div acts as a tooltip, which will presumably appear on hover over certain elements within the table row. The tooltip's position is dynamic based on the tooltipX and tooltipY values. -->
                    <div
                      class="fixed z-[1000] right-[500px] top-auto font-Poppins text-sm bg-white text-slate-950 rounded-md shadow-2xl p-4 transform scale-0 group-hover:scale-100 transition-all duration-300 ease-in-out min-w-[300px] max-w-[300px] shadow-slate-800"
                      :style="{
                        top: tooltipY + 'px',
                        left: tooltipX + 'px',
                        wordWrap: 'break-word',
                      }"
                    >
                      <!-- List of tooltip details -->
                      <ul class="space-y-2">
                        <!-- Displaying the creation date of the report -->
                        <li class="mb-2">
                          <strong class="mr-1">Created on:</strong>
                          {{ formatDate(report.createdAt) }}
                        </li>

                        <!-- Displaying the associated meters with the report -->
                        <li class="mb-2">
                          <strong class="mr-1">Associated meters:</strong>
                          <!-- Nested list for each associated meter -->
                          <ul class="ml-4">
                            <li
                              v-for="meter in sharedMeters.meters"
                              :key="meter.meterId"
                            >
                              <!-- Only show the meter ID if it is associated with the current report -->
                              <div v-if="meter.reports.includes(report.wordId)">
                                {{ meter.meterId }}
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>

            <!-- This table is displayed if isArchiveOpen is false. Presents a list of meters. -->
            <table
              v-else-if="!isArchiveOpen"
              class="text-sm text-left text-gray-500 w-[600px]"
            >
              <!-- Table header -->
              <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <!-- Column for checkboxes, allowing users to select all rows/meters -->
                  <th scope="col" class="p-4">
                    <div class="flex items-center">
                      <input
                        id="checkbox-all"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-dbs-green focus:ring-2"
                        :checked="isAllSelected"
                        @click="toggleAllMeters"
                      />
                      <label for="checkbox-all" class="sr-only">checkbox</label>
                    </div>
                  </th>
                  <!-- Column for meter names/IDs -->
                  <th scope="col" class="px-6 py-3">Meter</th>
                </tr>
              </thead>

              <!-- Table body -->
              <tbody>
                <!-- Rows of meters. Iterates over each meter in the meters array. -->
                <tr
                  @mousemove="
                    setCurrentMeterIdAndPosition(meter.meterId, $event)
                  "
                  :class="'bg-white border-b  hover:bg-gray-50  group cursor-pointer'"
                  v-for="(meter, index) in meters"
                  :key="index"
                >
                  <!-- Checkbox column, allowing users to select individual rows/meters -->
                  <td class="w-4 p-4">
                    <div class="flex items-center">
                      <input
                        id="checkbox-table-2"
                        type="checkbox"
                        class="w-4 h-4"
                        :value="meter"
                        v-model="selectedMeters"
                      />
                      <label for="checkbox-table-2" class="sr-only"
                        >checkbox</label
                      >
                    </div>
                  </td>
                  <!-- Meter ID column -->
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    <!-- Displaying an icon and the meter ID -->
                    <span class="mr-2">⚡</span> {{ meter.meterId }}

                    <!-- Tooltip that appears upon hovering over a meter. This tooltip provides additional details about associated reports for that meter. -->
                    <div
                      class="fixed z-[1000] right-[50px] top-auto font-Poppins text-sm bg-white text-slate-950 rounded-md shadow-2xl p-4 transform scale-0 group-hover:scale-100 transition-all duration-300 ease-in-out min-w-[300px] max-w-[300px] shadow-slate-800"
                      :style="{
                        top: tooltipY + 'px',
                        left: tooltipX + 'px',
                        wordWrap: 'break-word',
                      }"
                    >
                      <ul class="space-y-2">
                        <li class="mb-2">
                          <!-- Listing the reports associated with the currently hovered meter. -->
                          <strong class="mr-1">Associated reports:</strong>
                          <ul class="ml-4">
                            <!-- Iterating over each meter in the sharedMeters.meters array. -->
                            <li
                              v-for="meter in sharedMeters.meters"
                              :key="meter.meterId"
                            >
                              <!-- Checks if the currently hovered meter matches the meter in iteration. -->
                              <div v-if="currentMeterId === meter.meterId">
                                <!-- Listing each report for the matched meter. -->
                                <div
                                  v-for="report in meter.reports"
                                  :key="report"
                                >
                                  {{ report }}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </transition>
        </div>

        <!-- Container for the Share and Close buttons located at the bottom-right corner of the modal. -->
        <div class="absolute bottom-0 right-0 space-x-2 p-6 font-Poppins">
          <!-- Share/Stop button, whose behavior and appearance changes depending on the modal's state. -->
          <button
            :disabled="isButtonDisabled"
            @click="isArchiveOpen ? stopShare() : share()"
            :class="[
              'rounded-md w-20 h-10 bg-dbs-green2 text-white transition-colors duration-300 border-2 border-white',
              isButtonDisabled
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-white hover:text-black hover:border-dbs-header-gradient',
            ]"
          >
            <!-- Conditional rendering for button text. If the archive is not open, it displays "Share", otherwise "Stop". -->
            <span v-if="!isArchiveOpen">Share</span>
            <span v-else>Stop</span>
          </button>

          <!-- Close button to close the modal and reset the isArchiveOpen state. -->
          <button
            @click="closeModal(), (isArchiveOpen = false)"
            class="bg-dbs-green2 text-white transition-colors duration-300 w-20 h-10 hover:bg-white hover:text-black hover:border-dbs-header-gradient border-2 border-white rounded-md"
          >
            Close
          </button>
        </div>

        <!-- Container for the Share Archive button located at the bottom-left corner of the modal. -->
        <div class="absolute bottom-0 left-0 space-x-2 p-6 font-Poppins">
          <!-- Button to open or close the archive section within the modal. -->
          <button
            @click="openArchive"
            class="rounded-md w-36 h-10 bg-slate-400 text-white transition-colors duration-300 hover:bg-white hover:text-black hover:border-dbs-header-gradient border-2 border-white"
          >
            <!-- Conditional rendering for button text. If the archive is not open, it displays "Share archive", otherwise "Close archive". -->
            <span v-if="!isArchiveOpen">Share archive</span>
            <span v-else>Close archive</span>
          </button>
        </div>

        <!-- Closing tags for the surrounding divs and the overall transition effect of the modal. -->
      </div>
    </div>
  </transition>

  <!-- End of the Vue template section. -->
</template>

<script setup>
// Importing necessary modules and components
import { DateTime } from "luxon"; // DateTime library for date/time manipulation
import { useAuditStore } from "@/store/index"; // Importing a store to manage component state
import { ref, watchEffect, computed, watch } from "vue"; // Vue Composition API imports
import "vue3-toastify/dist/index.css"; // Toast notifications CSS
import { toast } from "vue3-toastify"; // Toast notifications
import api from "../api/portal.ts"; // API methods

// Initializing refs to manage state
const selectedMeters = ref([]); // List of selected meters
const selectedSharedMeters = ref([]); // List of selected shared meters
const isAllSelected = ref(false); // Boolean to check if all meters are selected
const isAllSharedSelected = ref(false); // Boolean to check if all shared meters are selected
const store = useAuditStore(); // Access the store state
const meters = store.meterList; // Meter list from the store
const sharedMeters = ref([]); // Ref for shared meters
const isLoading = ref(false); // Loading state
const isArchiveOpen = ref(false); // Archive open state
const currentMeterId = ref(null); // Currently selected meter ID
const tooltipX = ref(0); // Tooltip X-coordinate
const tooltipY = ref(0); // Tooltip Y-coordinate

// Set the current meter ID
function setCurrentMeterId(id) {
  currentMeterId.value = id;
}

// Set the current meter ID and tooltip position based on the mouse event
function setCurrentMeterIdAndPosition(id, event) {
  setCurrentMeterId(id);
  tooltipX.value = event.clientX + 80; // Adjusting X-coordinate for tooltip position
  tooltipY.value = event.clientY; // Setting Y-coordinate based on mouse event
}

// Computed property to decide if the share/stop button should be disabled
const isButtonDisabled = computed(() => {
  return (
    (isArchiveOpen.value && store.selectedSharedMeters.length === 0) ||
    (!isArchiveOpen.value && store.selectedMeters.length === 0)
  );
});

// Watch effect to check if all meters and shared meters are selected and update state accordingly
watchEffect(() => {
  if (store.sharedMeters && store.sharedMeters.relatedReports) {
    isAllSelected.value =
      store.meterList.length === selectedMeters.value.length;
    isAllSharedSelected.value =
      Object.keys(store.sharedMeters.relatedReports).length ===
      selectedSharedMeters.value.length;
    sharedMeters.value = store.sharedMeters;
  }
});

// Watch effect to set the selected meters and shared meters in the store
watchEffect(() => {
  store.setSelectedMeters(selectedMeters.value);
  store.setSelectedSharedMeters(selectedSharedMeters.value);
});

/**
 * Toggles the selection of all meters.
 * If all meters are currently selected, deselects them.
 * Otherwise, selects all meters from the store.
 */
const toggleAllMeters = () => {
  if (isAllSelected.value) {
    selectedMeters.value = [];
  } else {
    selectedMeters.value = [...store.meterList];
  }
};

/**
 * Toggles the selection of all shared meters.
 * If all shared meters are currently selected, deselects them.
 * Otherwise, selects all shared meters from their related reports in the store.
 */
const toggleAllSharedMeters = () => {
  if (isAllSharedSelected.value) {
    selectedSharedMeters.value = [];
  } else {
    selectedSharedMeters.value = store.sharedMeters.relatedReports.map(
      (meter) => meter.wordId,
    );
  }
};

/**
 * Formats a given ISO date string to a medium datetime format.
 * @param {string} dateString - The ISO date string to be formatted.
 * @returns {string} - Formatted date string.
 */
const formatDate = (dateString) => {
  return DateTime.fromISO(dateString, { zone: 'utc' }).setLocale('en-GB').toLocaleString(DateTime.DATETIME_MED);
};

/**
 * Toggles the archive open state.
 * If the archive is being opened, it also refreshes the sharedMeters data from the store.
 */
const openArchive = async () => {
  isArchiveOpen.value = !isArchiveOpen.value;

  // If the archive is now open, refresh the sharedMeters from the store
  if (isArchiveOpen.value) {
    isLoading.value = true;
    await store.collectSharedReports();
    isLoading.value = false;
  }
};

/**
 * Locks the scrolling when a modal is opened.
 * It also buffers the size of the scrollbar to avoid any visual shift in the page layout.
 */
const openModalScroll = () => {
  // Calculate the width of the scrollbar
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;

  // Adjust the margin to account for the scrollbar width
  document.body.style.marginRight = `${scrollbarWidth}px`;

  // Add a class to the body element to prevent scrolling
  document.body.classList.add("overflow-hidden");
  emit('open-modal-scroll');
};

/**
 * Resets the scrolling behavior after a modal is closed.
 * The function uses a 400ms delay to account for Vue transitions
 * to ensure the component doesn't shift visually during the transition.
 *
 * @returns {Promise} - Resolves after resetting the scroll behavior.
 */
const closeModalScroll = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      document.body.style.marginRight = "0px";
      document.body.classList.remove("overflow-hidden");
      resolve();
    }, 400);
    emit('close-modal-scroll');
  });
};

/**
 * Watches the modal's open status.
 * Adjusts scroll behavior based on whether the modal is opened or closed.
 */
watch(
  () => props.isOpen,
  (newVal, oldVal) => {
    if (newVal && !oldVal) {
      openModalScroll();
    } else if (!newVal && oldVal) {
      closeModalScroll();
    }
  },
);

/**
 * Generates and shares a link based on selected meters.
 * On successful share, it copies the generated link to the clipboard and notifies the user.
 * Handles any errors during the process and notifies the user accordingly.
 */
const share = async () => {
  try {
    isLoading.value = true;

    // Create a promise that resolves after 2 seconds so end user can't spam the button
    const delay = new Promise((resolve) => setTimeout(resolve, 2000));

    // Wait for both the API call and the 2-second timer to complete
    const [{ data }] = await Promise.all([
      api.post(`/v1/share`, { selectedMeters: selectedMeters.value }),
      delay,
    ]);

    if (data && data.reportName) {
      // Copy the unique phrase to clipboard based on environment
      if (process.env.NODE_ENV === "development") {
        await navigator.clipboard.writeText(
          `localhost:8080/share/${data.reportName}`,
        );
      } else {
        await navigator.clipboard.writeText(
          `https://insight.industrion.io/share/${data.reportName}`,
        );
      }

      isLoading.value = false;

      // Notify the user of success
      toast.success(
        "Share link generated and copied to clipboard, you can now paste to a third party",
        {
          icon: "🔓",
          multiple: true,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 4000,
          progressStyle: { background: "#3AB1A6" },
          toastStyle: {
            fontSize: "14px",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "bold",
          },
        },
      );
    } else {
      // Notify the user of an error
      toast.error("Something went wrong; response data is incomplete.");
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;
    // Handle different types of errors and notify the user
    /* Error handling code here */
  }
};

/**
 * Stops sharing for selected shared meters.
 * Iterates over shared meters and sends a delete request for each.
 * On success, it updates the store and notifies the user.
 * Handles any errors during the process.
 */
const stopShare = async () => {
  try {
    isLoading.value = true;

    const selectedSharedMetersCopy = [...store.selectedSharedMeters];

    for (const meter of selectedSharedMetersCopy) {
      const wordId = meter;
      const { data } = await api.delete(`/v1/share/${wordId}`);

      if (data.message === "Report deleted successfully") {
        store.removeFromSelectedSharedMeters(wordId);

        // Notify the user of success
        toast.success(
          "Share link has been removed, third party no longer has access",
          {
            icon: "🔒",
            multiple: true,
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 4000,
            progressStyle: { background: "#3AB1A6" },
            toastStyle: {
              fontSize: "14px",
              fontFamily: '"Poppins", sans-serif',
              fontWeight: "bold",
            },
          },
        );
        await store.collectSharedReports();
        
      } else {
        toast.error("Something went wrong; response data is incomplete.");
        isLoading.value = false;
        
      }
    }
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    console.error(error);
    emit("error")
  }
};

// Defines properties for the Vue component
const props = defineProps({ isOpen: Boolean });

// Defines events that can be emitted from the Vue component
const emit = defineEmits([]);

/**
 * Emits the "close" event, typically used to signal
 * that the modal should be closed.
 */
const closeModal = () => {
  emit("close");
};
defineExpose({ isArchiveOpen, openArchive,  closeModalScroll, openModalScroll, stopShare, closeModal });
</script>
<style scoped>
/* Loader styles */
.loader {
  /* Rotating animation applied to the loader */
  animation: rotate 3s infinite;
  height: 50px;
  width: 50px;
  /* Slight transparency applied to the loader */
  opacity: 73%;
}

/* Common styles for the pseudo-elements of the loader */
.loader:before,
.loader:after {
  /* Makes the pseudo-elements oval-shaped */
  border-radius: 20%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

/* Specific styles for the 'before' pseudo-element of the loader */
.loader:before {
  /* Animation for the first ball */
  animation: ball1 1s infinite;
  background-color: #2b978d;
  /* Creates a shadow to simulate a second ball */
  box-shadow: 30px 0 0 #393b3c;
  margin-bottom: 10px;
}

/* Specific styles for the 'after' pseudo-element of the loader */
.loader:after {
  /* Animation for the second ball */
  animation: ball2 1s infinite;
  background-color: #393b3c;
  /* Creates a shadow to simulate another ball */
  box-shadow: 30px 0 0 #2b978d;
}

/* Rotation animation keyframes */
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

/* First ball movement animation keyframes */
@keyframes ball1 {
  0%,
  100% {
    box-shadow: 30px 0 0 #393b3c;
    margin-bottom: 10px;
  }
  50% {
    box-shadow: 0 0 0 #393b3c;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
}

/* Second ball movement animation keyframes */
@keyframes ball2 {
  0%,
  100% {
    box-shadow: 30px 0 0 #2b978d;
    margin-top: 0;
  }
  50% {
    box-shadow: 0 0 0 #2b978d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
}

/* Fade-in/fade-out animation styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

/* Slide-up and fade-in/fade-out animation styles */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
