import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/page-not-found.vue";
import meterAuditVue from "@/views/meter-audit.vue";
import { registerGuard } from "./Guard";

const routes = [
  {
    path: "/",
    name: "Meter Audit",
    component: meterAuditVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/share/:word_id",
    name: "Meter Audit Share",
    component: meterAuditVue,
  },
  // plan on directly navigating to the not-found route using its name
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  { path: "/:pathMatch(.*)", name: "bad-not-found", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"] },
}).href; // '/not/found'

registerGuard(router);

export default router;
