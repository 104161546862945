<template>
  <div>
    <div class="py-2">
      <span class="font-Poppins text-dbs-light-black"
        ><strong>Day/Hour Heatmap Usage</strong></span
      >
    </div>
    <VueApexCharts
      :options="heatmapDayTrendChart.options"
      :series="props.seriesData"
      height="300"
      width="1030"
    >
    </VueApexCharts>
  </div>
</template>

<script setup>
import { computed, toRaw } from "vue";
import VueApexCharts from "vue3-apexcharts";

const props = defineProps({
  seriesData: {
    type: Array,
    required: false,
  },
  categories: {
    type: Array,
    required: false,
  },
});

const maxValue = computed(() => {
  let check = [];
  toRaw(props.seriesData).forEach((item) => {
    check.push(...item.data);
  });
  const max = Math.max(...check.map((i) => i.y));
  return max;
});

const minValue = computed(() => {
  let check = [];
  toRaw(props.seriesData).forEach((item) => {
    check.push(...item.data);
  });
  const min = Math.min(...check.map((i) => i.y));
  return min;
});

const heatmapDayTrendChart = computed(() => {
  return {
    options: {
      chart: {
        toolbar: {
          show: true,
        },
        type: "heatmap",
      },
      noData: {
        text: "No data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
      plotOptions: {
        heatmap: {
          enableShades: true,
          shadeIntensity: 0.9,
          radius: 5,
          colorScale: {
            ranges: [
              {
                from: minValue.value,
                to: minValue.value + 1,
                name: "Usage",
                color: "#2B978D",
              },
              {
                from: minValue.value + 1,
                to: maxValue.value - 1,
                name: "Usage",
                color: "#0C1E36",
              },
              {
                from: maxValue.value,
                to: maxValue.value + 1,
                name: "Usage",
                color: "#FD6213",
              },
            ],
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 2,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 900,
            },
            
          },
        },
        {
        breakpoint: 767,
        options: {
          chart: {
            width: 1030,
          },
          
        },
      },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 470,
            },
           
          },
        },
        {
          breakpoint: 1040,
          options: {
            chart: {
              width: 700,
            },
           
          },
        },
      ],
    },
  };
});
</script>
