<template>
  <div class="flex justify-center items-center flex-col">
    <img src="@/assets/404.svg" class="h-96 w-96 pt-32 pb-10" />
    <span v-if="!store.meterList.length" class="font-Poppins text-2xl pb-32 font-extralight"
      >You have no meters 😔</span
    ><span v-else class="font-Poppins text-2xl pb-32 font-extralight"
      >Page not found 😔</span
    >
  </div>
</template>

<script setup>
import { useAuditStore } from "@/store/index";
const store = useAuditStore();
</script>


<style scoped></style>
