<template>
  <!-- Dropdown Wrapper -->
  <div ref="dropdown">
    <!-- Dropdown Toggle Button -->
    <button
      @click="show = !show"
      class="flex justify-between items-center text-dbs-faded-grey font-Poppins bg-white font-normal py-2 px-4 whitespace-no-wrap rounded transition duration-300 w-44"
    >
      <div class="flex items-center">
        <!-- Display Selected Year -->
        <span :class="{ 'text-dbs-green2': store.selectedYear !== '' }">
          {{ store.selectedYear || "Select Year" }}
          <i v-if="store.selectedYear" class="ml-1"></i>
        </span>
      </div>
      <!-- Chevron icon indicating dropdown state. Rotates based on dropdown visibility -->
      <svg
        class="text-slate-400 fill-current"
        :class="{ 'rotate-180': show }"
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path
          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
        />
      </svg>
    </button>

    <!-- Dropdown List -->
    <transition :name="animation">
      <div
        @click="!show"
        style="cursor: pointer"
        class="text-dbs-faded-grey mt-1 font-Poppins rounded bg-white absolute z-10 shadow-2xl"
        v-if="show"
      >
        <ul class="list-none overflow-hidden rounded w-44" id="shittylist">
          <!-- Loop through 'years' to create list items -->
          <li
            v-for="(year, index) in years"
            :key="index"
            :class="{ ' text-dbs-green2': year === store.selectedYear }"
          >
            <a
              @click="selectYear(year)"
              class="flex py-2 px-4 transition duration-300"
            >
              <span class="mr-2"></span> {{ year }}
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
// Import Vue APIs and store
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useAuditStore } from "@/store/index";

// Store setup
const store = useAuditStore();

// Dropdown references and states
const dropdown = ref(null);
const show = ref(false);

// Year list reference
const years = ref([...store.meterData.years]);

// Function to set the selected year
const selectYear = (year) => {
  store.selectedYear = year;
  show.value = false;
};

// Watch for changes in years and update the 'years' ref
watch(
  () => store.meterData.years,
  (newValue) => {
    years.value = newValue;
  },
);

// Set 'Last 12 Month' as the default year when the meter changes
watch(
  () => store.selectedMeter,
  () => {
    store.selectedYear = "Last 12 Month";
  },
);

// Handle click outside to close dropdown
const handleClickOutside = (event) => {
  if (!dropdown.value.contains(event.target)) {
    show.value = false;
  }
};

// Add the click outside event listener on mount
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

// Remove the click outside event listener on unmount
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

// Define component props
// eslint-disable-next-line no-undef
defineProps({
  animation: String,
});
</script>

<style scoped>
/* Dropdown button styling */
button {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

/* Animation styles for slide-in-left */
.slide-in-left-enter-from,
.slide-in-left-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-in-left-enter-to,
.slide-in-left-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.slide-in-left-enter-active,
.slide-in-left-leave-active {
  transition: all 0.5s;
}

/* Animation styles for slide-in-up */
.slide-in-up-enter-active,
.slide-in-up-leave-active {
  transition: all 0.5s;
  transform: translateY(0);
}
.slide-in-up-enter-from,
.slide-in-up-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
