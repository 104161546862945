import "./assets/tailwind.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { msalPlugin } from "./plugins/msalPlugin.js";
import { msalInstance } from "./authConfig";
import { EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient.js";

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.LOGIN_FAILURE || event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    msalInstance.loginRedirect();
  }
});

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

app.use(msalPlugin, msalInstance);
app.use(createPinia());
app.use(router);

router.isReady().then(() => {
  app.mount("#app");
});
