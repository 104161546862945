import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "e8ee7e5c-ba4d-4a0d-879c-d1f61fbd48b7",
    authority:
      "https://login.industrion.io/ukdbg.onmicrosoft.com/B2C_1_user_pass",
    knownAuthorities: ["https://login.industrion.io/"],
    redirectUri: "https://insight.industrion.io/", // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
    allowNativeBroker: false,
  },
};
if (process.env.NODE_ENV !== "production") {
  msalConfig.auth.redirectUri = "http://localhost:8080/";
}

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    "https://ukdbg.onmicrosoft.com/e8ee7e5c-ba4d-4a0d-879c-d1f61fbd48b7/portal.user.read",
  ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
