<template>
  <Transition>
    <!-- Loading state container: Only displays if 'isMeterDataLoading' and 'isInitialLoad' are true -->
    <div v-if="store.isInitialLoad" class="items-center py-20 justify-center overflow-hidden">
      <!-- Flex container for loading image -->
      <div class="flex py-4 items-center justify-center space-x-2 mt-16">
        <!-- Loading image: Windmill gif -->
        <img src="@/assets/Windmill_1.gif" class="animated-element2 w-72 h-72" :key="animationKey"/>
              </div>

      <!-- Flex container for loading text -->
      <div class="flex text-l  justify-center mt-10 flex-col items-center" >
        <!-- Loading text: 'Getting everything ready...' -->
        
          <span class="text-dbs-navy font-medium text-lg overflow-hidden animated-element  " id="loadingText" @animationend="animationEnded" :key="animationKey2" >
            {{ loadingPhrase }}
          </span>
          <span class= " animated-element mt-6" :key="animationKey3" >
            ⚡
          </span>
        
         
        
          
        
        
      </div>
    </div>

    <!-- If 'isInitialLoad' is false, display the following content -->
    <!-- This div serves as the container for the main content when the initial load is not happening. -->
    <div v-else class="flex overflow-hidden">
      <!-- Document Container -->
      <!-- This div contains the full document, with a gray background and hidden overflow on the x-axis. -->
      <div class="w-full overflow-x-hidden px-4  bg-gray-200" id="fullDoc">
        <!-- Logo and Meter ID Container -->
        <!-- This div contains the company logo and Meter ID. -->
        <div class="flex flex-col mb-12 mt-6 print:-mt-6 print:mb-6 print:mr-20" id="desLogo">
          <!-- Company Logo -->
          <!-- Display the company logo with specific width and height. -->
          <img
            src="@/assets/Dark-Insight-Logo.svg"
            class="w-28 h-28 -mb-8"
            id="desLogo1"
          />

          <!-- Meter ID Text -->
          <!-- Text displaying the selected Meter ID from the store. -->
          <!-- <div class="w-28 text-center">
            <span class="text-xs">Meter ID: {{ store.selectedMeter }}</span>
          </div> -->
        </div>

        <!-- Share Modal -->
        <!-- Displays the sharing model component if the current route name is 'Meter Audit'. -->
        <appShareModel
          v-if="route.name === 'Meter Audit'"
          :is-open="isModalOpen"
          @close="closeModal"
        ></appShareModel>

        <!-- PDF Intro Text (Hidden) -->
        <!-- Hidden section that contains introductory text for a PDF. -->
        <div class="hidden flex-col mb-12" id="pdfIntro">
          <div class="pl-12 print:pl-0 max-w-4xl">
            <span class="font-Poppins font-bold text-5xl print:text-3xl"
              >Hi! Here's your insight,
              <span class="text-dbs-green"
                >{{ store.meterData.customer.businessName }}.</span
              ></span
            >
            <div class="pt-7">
              <span class="font-Poppins font-normal text-xl print:text-base"
                >You can also view this report in more depth online.</span
              >
            </div>
          </div>
        </div>
        <div class="md:hidden print:hidden min-w-0 flex justify-center items-center">
          <img src="@/assets/Dark-Insight-Logo.svg" class="w-40 h-20" />
        </div>

        <!-- Top Navigation for Medium Screens and Up -->
        <!-- Contains logo, meter selector and year selector. Hidden on small screens. -->
        <div class="flex justify-center">
          <div
            class="md:grid   hidden grid-cols-3 items-center h-20 mx-auto mb-14 mt-4 w-[1090px]"
            id="topNav"
          >
            <!-- Logo -->
            <div class="min-w-0 flex flex-start">
              <img src="@/assets/Dark-Insight-Logo.svg" class="w-40 h-20" />
            </div>
            <!-- Meter Selector -->
            <div class="flex justify-center z-50">
              <meterSelect
                animation="slide-in-up" 
                id="meterSelectButton"
              ></meterSelect>
            </div>
            <!-- Year Selector -->
            <div class="flex justify-end">
              <yearSelect animation="slide-in-up" id="pdfbutton"></yearSelect>
            </div>
          </div>
        </div>

        <!-- Meter Selector for Small Screens -->
        <!-- Displays meter selector specifically for small screens. -->
        <div class="flex justify-center md:hidden pt-4 pb-8 z-50 print:hidden">
          <meterSelect animation="slide-in-up"></meterSelect>
        </div>

        <!-- Main container div -->
        <div
          class="relative mb-4 sm:grid sm:grid-cols-4 flex flex-row rounded-lg md:mx-auto items-center max-w-[740px] h-auto min-h-[100px] md:max-w-[1090px] md:min-h-[200px] bg-white pl-4 sm:pl-0 mt-4 sm:mt-10 gap-2 break-words"
          id="welcome"
        >
          <!-- Text container div -->
          <div class="col-span-3">
            <!-- Container for the welcome message -->
            <div class="mx-auto my-auto flex-col sm:pl-4 md:pl-12 flex">
              <!-- Welcome back message -->
              <span
                class="md:text-[26px] sm:text-lg text-sm font-extrabold sm:leading-[34px] font-Poppins order-1 md:order-none max-w-[300px] sm:max-w-[390px] md:max-w-[450px] lg:max-w-[690px] pt-4 md:pt-6"
              >
                Hi! Welcome back,
                <!-- Customer business name -->
                <span class="text-dbs-green">{{
                  store.meterData.customer.businessName
                }}</span
                >.
              </span>
              <!-- Subtext about energy insight -->
              <div
                class="md:text-base text-xxs font-light pt-2 sm:pt-4 font-Poppins order-2 md:order-none pb-4 md:pb-0"
              >
                Let's take a look at your energy insight.
              </div>

              <!-- Container for buttons -->
              <div class="md:flex items-center hidden gap-2 pb-4">
                <!-- "Your PDF" button -->
                <button
                  v-if="route.name === 'Meter Audit'"
                  class="w-40 h-12 bg-dbs-green2 text-white font-bold transition-colors duration-300 hover:bg-white hover:text-black hover:border-dbs-header-gradient border-2 border-white hidden md:inline md:order-none rounded-md" id="hiddenbutt"
                  @click="
                    printToPdf()
                  "
                >
                  <!-- PDF button text / loader -->
                  <transition name="slide-fade" mode="out-in">
                    <span v-if="!isLoading" class="font-medium font-Poppins"
                      >Your PDF</span
                    >
                    <span v-else>
                      <!-- Loader -->
                      <div class="flex flex-row items-center justify-evenly">
                        <div class="font-medium font-Poppins">One moment</div>
                        <span class="text-sm bounce-continuous">⚡</span>
                      </div>
                    </span>
                  </transition>
                </button>
                <!-- "Share a report" button -->
                <button
                  v-if="route.name === 'Meter Audit'"
                  class="w-40 h-12 bg-dbs-green2 text-white font-bold transition-colors duration-300 hover:bg-white hover:text-black hover:border-dbs-header-gradient border-2 border-white hidden md:inline md:order-none rounded-md "
                  @click="openModal"
                >
                  <span class="font-medium font-Poppins">Share a report</span>
                </button>
                <!-- Loading container -->
                <div
                  class="loading-container w-60 h-20 flex items-center"
                ></div>
              </div>
            </div>
          </div>
          <!-- Image container -->
          <div class="col-span-1">
            <img
              src="@/assets/newbulb.svg"
              class="md:-mt-20 -mt-8 h-32 md:h-60 md:scale-150 scale-110 transform md:translate-x-[-40%] sm:translate-x-[-35%] translate-y-[-5%] translate-x-[-5%] lg:translate-y-0 z-10"
            />
          </div>
        </div>
        <!-- Another container below the main one -->
        <div
          class="flex mx-auto gap-2 max-w-[1090px] flex-col sm:flex-row pt-4"
        >
          <!-- Container for business name and MPAN -->
          <div
            class="flex gap-1 flex-col justify-center md:flex-row print:flex-col text-xs md:text-base h-[100px] rounded-lg md:min-h-[120px] bg-white sm:w-[70%] max-w-[740px] md:max-w-[1090px] mb-2"
            id="pdfBeggining"
          >
            <!-- Container for company info -->
            <div class="flex items-center justify-center md:mr-auto print:mr-auto md:pl-12">
              <!-- Text container for business name and MPAN -->
              <div class="flex flex-col justify-center">
                <span
                  class="font-Poppins font-extrabold text-lg text-dbs-light-black pb-2"
                  >{{ store.meterData.customer.businessName }}</span
                >
                <span class="font-extralight font-Poppins"
                  >MPAN: {{ store.meterData.customer.mpan }}</span
                >
              </div>
            </div>
          </div>
          <!-- Carbon impact container -->
          <div
            class="flex flex-row text-xs md:text-base print:text-base max-w-[740px] rounded-lg justify-center md:max-w-[1090px] sm:w-[30%] max-h-[101px] md:max-h-[120px] bg-white p-2 break-words"
            id="pdfBeggining"
          >
            <!-- Container for carbon impact details -->
            <div class="flex flex-col justify-center h-full mx-auto md:mx-0">
              <!-- Carbon Impact title -->
              <div
                class="lg:text-base text-xs flex items-center justify-center font-Poppins whitespace-nowrap sm:pb-2"
              >
                Carbon Impact - {{ data.selectedYear }}
              </div>

              <!-- Container for the carbonIntensity and loading icon -->
              <div
                class="relative flex items-center justify-center h-10 w-full pt-2 print:pt-0 print:h-6"
              >
                <transition name="slide-fade" mode="out-in">
                  <!-- Carbon Intensity element -->
                  <div
                    v-if="!store.isMeterDataLoading"
                    class="absolute flex items-center justify-center"
                  >
                    <carbonIntensity
                      :totalConsumption="
                        store.meterData && store.meterData.carbonIntensity
                          ? store.meterData.carbonIntensity[data.selectedYear]
                          : []
                      "
                    />
                  </div>
                  <!-- Loading icon -->
                  <div
                    v-else
                    class="absolute flex items-center justify-center citest bounce-continuous"
                  >
                    ⚡
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>

        <!-- Container with flex properties to center its children -->
        <div class="flex justify-center items-center p-5 print:hidden">
          <!-- Button that only shows up if the route name is 'Meter Audit' -->
          <button
            v-if="route.name === 'Meter Audit'"
            class="w-40 h-12 bg-dbs-green2 text-white font-bold transition-colors duration-300 hover:bg-white hover:text-black hover:border-dbs-header-gradient border-2 md:order-none rounded-md md:hidden"
            @click="openModal"
          >
            <!-- Calls openModal method when clicked -->
            <!-- Button label -->
            <span class="font-medium font-Poppins">Share a report</span>
          </button>
        </div>

        <!-- Text description that is hidden on medium and larger screens -->
        <div
          class="md:hidden font-Poppins text-sm text-center pb-5 text-dbs-light-black font-light print:hidden"
        >
          Select a year below and click 'Your PDF' to see your insight for that period. This will
          generate a pdf report for better mobile compatibility
        </div>

        

        <!-- Container to hold the '12 month', '2022' and '2023' buttons and loading indicator, only shown on small screens -->
        <div class="flex flex-col justify-center items-center md:hidden print:hidden">
          <!-- Row container for buttons -->
          <div class="flex flex-row justify-items-center gap-3">
            <div class="flex md:hidden print:hidden justify-center items-center pb-6">
          <yearSelect animation="slide-in-up" id="pdfbutton"></yearSelect>
        </div>
           
            <button
              class="w-24 h-10 bg-dbs-green2 text-white font-bold transition-colors duration-300 hover:bg-white hover:text-black hover:border-dbs-header-gradient md:order-none md:hidden rounded-md"
              :disabled="isLoading"
              @click="printToPdf()
              
              "
            >
            <transition name="slide-fade" mode="out-in">
                    <span v-if="!isLoading" class="font-medium font-Poppins"
                      >Your PDF</span
                    >
                    <span v-else>
                      <!-- Loader -->
                      <div class="flex flex-row items-center justify-evenly">
                        <span class="text-sm bounce-continuous">⚡</span>
                      </div>
                    </span>
                  </transition>
            </button>
          </div>

          <!-- Loading Indicator Container -->

          <div
            class="loading-container w-60 h-10 flex flex-col justify-center items-center"
          >
            <transition name="slide-fade">
              <div v-if="isLoading" class="text-xs font-Poppins items-center">
                fetching your insight
              </div>
            </transition>
          </div>
        </div>
        <!-- Consumption Insight title -->
        <div
          class="hidden relative md:flex mx-auto mt-4 text-dbs-light-black font-Poppins max-w-[1070px]"
        >
          Comsumption insight: {{ data.selectedYear }}
        </div>

        <!-- Main Container for Chart and Accordion -->
        <!-- 
  This div serves as the main container for the chart and the accordion. 
  It is hidden on small screens and only displays on medium and larger screens.
  The layout is flex-based and set to allow overflow.
-->
        <div
          class="hidden md:flex print:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center items-center max-w-[1090px] mt-9 print:mt-0 rounded-lg bg-white print:h-64 print:overflow-hidden "
        >
          <!-- Bar Chart Container -->
          <!-- 
    This div serves as a container for the bar chart.
    It passes data from the store and categories as props to the 'bar-chart' component.
  -->
          <div class="h-auto pt-7">
            <bar-chart
              :series-data="
                store.meterData &&
                store.meterData.monthlyKWHConsumption &&
                store.meterData.monthlyKWHConsumption[0]
                  ? store.meterData.monthlyKWHConsumption[0][data.selectedYear]
                  : []
              "
              :categories="categories"
            class="print:scale-[60%]"/>
          </div>

          <!-- Accordion for Chart Explanation -->
          <!-- 
    This is an accordion component named 'appAccordion'.
    It contains an explanation for the Monthly kWh Consumption.
  -->
          <appAccordion id="accordion">
            <div>
              <!-- Accordion Title -->
              <strong class="text-sm">Monthly kWh Consumption</strong>

              <!-- Accordion Content -->
              <p class="text-sm">
                This bar chart shows you the total historic kWh consumption
                broken down by month. The data will typically be reflected in
                your monthly energy supplier bills provided the billing date
                aligns with month end.
              </p>
            </div>
          </appAccordion>
        </div>

        <!-- Main Container for Pie Chart and Accordion -->
        <!--
  This div serves as the main container for both the pie chart and the accordion. 
  It is designed to be hidden on small screens and is displayed on medium and larger screens.
  The layout is flex-based, vertically aligned and is set to allow overflow.
-->
        <div
          class="hidden md:flex print:flex print:overflow-hidden print:mt-0 flex-col h-auto overflow-auto mx-auto justify-center items-center max-w-[1090px] mt-9 rounded-lg bg-white"
          id="pie"
        >
          <!-- Chart Title -->
          <!-- 
    This span element displays the title of the pie chart, 'Time of Day Consumption',
    and it is styled with extra bold font weight.
  -->
          <span class="font-Poppins font-extrabold whitespace-nowrap pt-9 pb-7 print:pb-0">
            Time of Day Consumption
          </span>

          <!-- Pie Chart Container -->
          <!-- 
    This is the pie chart component that takes 'series-data' as a prop.
    The data for the pie chart is fetched from the store based on the selected year.
  -->
          <pie-chart
            :series-data="
              store.meterData.timeOfDayConsumption[data.selectedYear]
            "
          class=" print:scale-75 "/>

          <!-- Accordion for Pie Chart Explanation -->
          <!-- 
    This is an accordion component named 'appAccordion' that contains an
    explanation of what the pie chart represents.
  -->
          <appAccordion id="accordion">
            <div>
              <!-- Accordion Title -->
              <strong class="text-sm">Time of Day Consumption</strong>

              <!-- Accordion Content -->
              <p class="text-sm">
                This is a simple way to appraise potential energy waste
                particularly when considering energy consumed outside of your
                sites normal operating hours. Reducing energy consumption starts
                with identifying when, and where, it could be being wasted.
              </p>
            </div>
          </appAccordion>
        </div>

        <!-- Logo and Meter ID Container -->
        <!--
  This div serves as a container for the logo and Meter ID. 
  It is flexbox-based and aligned vertically.
-->
        <div class="flex flex-col mb-12 mt-6 print:mb-0" id="desLogo">
          <!-- Logo Image -->
          <!-- 
    This img tag displays the Dark Insight Logo from the assets directory.
    The dimensions of the logo are set to be 28x28.
  -->
          <img
            src="@/assets/Dark-Insight-Logo.svg"
            class="w-28 h-28 -mb-8"
            id="desLogo1"
          />

          <!-- Meter ID Display -->
          <!-- 
    This div contains the Meter ID fetched from the store, 
    displayed in the center below the logo.
  -->
          <!-- <div class="w-28 text-center">
            <span class="text-xs">Meter ID: {{ store.selectedMeter }}</span>
          </div> -->
        </div>

        <!-- Year Information for Mapped Insight -->
        <!--
  This div shows the selected year for mapped insights. 
  It is displayed only on medium and larger screens.
-->
        <div
          class="hidden relative md:flex mx-auto text-dbs-light-black font-Poppins max-w-[1070px] mt-10"
        >
          Mapped insight: {{ data.selectedYear }}
        </div>

        <!-- Main Container for Heatmap and Accordion -->
        <!--
  This div serves as the main container for both the heatmap and the accordion. 
  It is designed to be hidden on small screens and is displayed on medium and larger screens.
  The layout is flex-based and vertically aligned.
-->
        <div
          class=" absolute top-[-9999px] left-[-9999px] md:static  print:static print:top-auto print:left-auto print:flex md:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center max-w-[1090px] mt-9 rounded-lg bg-white print:h-64 print:overflow-hidden print:mt-10"
        >
          <!-- Heatmap Container -->
          <!-- 
    This div contains the heatmap-day-trend component and aligns it at the center.
    It passes data from the store based on the selected year as props to the heatmap.
  -->
          <div class="h-auto self-center pt-7 ">
            <heatmap-day-trend
              :series-data="store.meterData.timeOfDayHeatmap[data.selectedYear]"
              class="print:scale-[60%] "/>
          </div>

          <!-- Accordion for Heatmap Explanation -->
          <!-- 
    This is an accordion component named 'appAccordion' that contains an
    explanation for the heatmap.
  -->
          <appAccordion id="accordion">
            <div class="text-sm">
              <!-- Accordion Title -->
              <strong class="font-Poppins">Day/Hour heatmap usage</strong>

              <!-- Accordion Content -->
              <p>
                This heatmap shows the average energy intensity at hourly
                intervals across a typical day of the week. This is particularly
                useful when overlayed with your operating hours as it provides a
                more granular view of where we might find energy waste and
                therefore opportunities to save. This heatmap also supports an
                initial assessment of site suitability and potential system size
                for renewable generation projects. As different generating
                assets have different generating profiles, knowing when you need
                to use energy aids this initial assessment.
              </p>
            </div>
          </appAccordion>
        </div>

        <!-- Main Container for Half-Hour Trend Chart and Accordion -->
        <!--
  This div serves as the main container for both the Half-Hour Trend Chart and the accordion.
  It is designed to be hidden on small screens and is displayed on medium and larger screens.
  The layout is flex-based, vertically aligned, and allows for overflow.
-->
        <div
          class="absolute top-[-9999px] left-[-9999px] md:static print:static print:top-auto print:left-auto print:flex md:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center max-w-[1090px] mt-9 rounded-lg bg-white print:mt-0 print:h-64 print:overflow-hidden"
        >
          <!-- Half-Hour Trend Chart Container -->
          <!-- 
    This div contains the HalfHourTrend component and aligns it at the center.
    The component takes in 'series-data' as a prop, where the data is fetched
    from the store based on the selected year.
  -->
          <div class="h-auto self-center pt-7">
            <HalfHourTrend
              :series-data="store.meterData.halfHourChart[data.selectedYear]"
            class="print:scale-[60%] "/>
          </div>

          <!-- Accordion for Half-Hour Trend Chart Explanation -->
          <!-- 
    This is an accordion component named 'appAccordion' that contains
    an explanation for the Half-Hour Trend Chart.
  -->
          <appAccordion id="accordion">
            <div class="text-sm">
              <!-- Accordion Title -->
              <strong>Day/Hour heatmap usage</strong>

              <!-- Accordion Content -->
              <p>
                This heatmap shows the average energy intensity at hourly
                intervals across a typical day of the week. This is particularly
                useful when overlayed with your operating hours as it provides a
                more granular view of where we might find energy waste and
                therefore opportunities to save. This heatmap also supports an
                initial assessment of site suitability and potential system size
                for renewable generation projects. As different generating
                assets have different generating profiles, knowing when you need
                to use energy aids this initial assessment.
              </p>
            </div>
          </appAccordion>
        </div>

        <!-- Main Container for the Heatmap Month Trend Chart -->
        <div
          class="absolute top-[-9999px] left-[-9999px] md:static print:static print:top-auto print:left-auto print:flex md:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center max-w-[1090px] mt-9 rounded-lg bg-white print:mt-0  print:h-64 print:overflow-hidden"
          id="graphOne"
        >
          <!-- Container for the HeatmapMonthTrend component -->
          <!-- 
    This div contains the HeatmapMonthTrend component.
    The component receives 'series-data' as a prop, which is based on the selected year from the store's meterData.
  -->
          <div class="h-auto self-center pt-7">
            <HeatmapMonthTrend
              :series-data="
                store.meterData.timeOfMonthHeatmap[data.selectedYear]
              "
            class="print:scale-[60%] "/>
          </div>

          <!-- Accordion containing information about the Heatmap Month Trend -->
          <appAccordion id="accordion">
            <div class="text-sm">
              <!-- Accordion Title -->
              <strong>Day/Hour heatmap usage</strong>

              <!-- Accordion Content -->
              <p>
                This heatmap shows the average energy intensity at hourly
                intervals across a typical day of the week. This is particularly
                useful when overlayed with your operating hours as it provides a
                more granular view of where we might find energy waste and
                therefore opportunities to save. This heatmap also supports an
                initial assessment of site suitability and potential system size
                for renewable generation projects. As different generating
                assets have different generating profiles, knowing when you need
                to use energy aids this initial assessment.
              </p>
            </div>
          </appAccordion>
        </div>

        <div class="flex flex-col mb-12 mt-6" id="desLogo">
          <!-- Logo Image -->
          <img
            src="@/assets/Dark-Insight-Logo.svg"
            class="w-28 h-28 -mb-8"
            id="desLogo1"
          />
        </div>

        <!-- Main Container for the Half-Hour Trend Month Chart -->
        <div
          class="absolute top-[-9999px] left-[-9999px] md:static print:static print:top-auto print:left-auto md:flex print:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center max-w-[1090px] mt-9 rounded-lg bg-white print:mt-0 print:h-64 print:overflow-hidden"
        >
          <!-- Container for the HalfHourTrendMonth component -->
          <!-- 
    This div contains the HalfHourTrendMonth component.
    The component receives 'series-data' as a prop, which is based on the selected year from the store's meterData.
  -->
          <div class="h-auto self-center pt-7">
            <HalfHourTrendMonth
              :series-data="
                store.meterData && store.meterData.monthlyhalfHourTrendChart
                  ? store.meterData.monthlyhalfHourTrendChart[data.selectedYear]
                  : []
              "
            class="print:scale-[60%] "/>
          </div>

          <!-- Accordion containing information about the Half-Hour Trend Month -->
          <appAccordion id="accordion">
            <div class="text-sm">
              <!-- Accordion Title -->
              <strong>Day/Hour heatmap usage</strong>

              <!-- Accordion Content -->
              <p>
                This heatmap shows the average energy intensity at hourly
                intervals across a typical day of the week. This is particularly
                useful when overlayed with your operating hours as it provides a
                more granular view of where we might find energy waste and
                therefore opportunities to save. This heatmap also supports an
                initial assessment of site suitability and potential system size
                for renewable generation projects. As different generating
                assets have different generating profiles, knowing when you need
                to use energy aids this initial assessment.
              </p>
            </div>
          </appAccordion>
        </div>

        <!-- Display Selected Year for Mapped Insights -->
        <div
          class="hidden relative md:flex mx-auto mt-10 text-dbs-light-black font-Poppins max-w-[1070px]"
        >
          Mapped insight: {{ data.selectedYear }}
        </div>

        <!-- Container for Line Area Chart and Related Information -->
        <div
          class="absolute top-[-9999px] left-[-9999px] md:static print:static print:top-auto print:left-auto md:flex print:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center max-w-[1090px] mt-9 rounded-lg bg-white print:mt-0 print:h-64 print:overflow-hidden"
        >
          <!-- Display Line Area Chart -->
          <div class="h-auto self-center pt-7" >
            <LineAreaChart
              :series-data="
                store.meterData.totalPerDayMinMax[data.selectedYear]
              "
            class="print:scale-[60%] "/>
          </div>

          <!-- Accordion for Additional Information -->
          <appAccordion id="accordion">
            <div class="text-sm">
              <strong>Comparison Usage</strong>
              <p>
                The graph shows the highest (peak) and lowest (base) energy
                consumption on any given day. Reducing peak demand can
                significantly reduce your energy invoice. This is particularly
                pertinent considering the impact of the
                <a
                  class="font-bold text-dbs-green"
                  href="https://www.nationalgrideso.com/industry-information/charging/transmission-network-use-system-tnuos-charges"
                  target="_blank"
                  >TNUoS</a
                >
                charging methodology changes implemented in April 2023. Base
                demand is the 'always on' energy demand across your estate;
                finding where this energy is consumed, and taking steps to
                reduce it, will deliver a permanent reduction in your energy
                consumption.
              </p>
            </div>
          </appAccordion>
        </div>

        

        <!-- Container for Line Chart and Related Information -->
        <div
          class="absolute top-[-9999px] left-[-9999px] md:static print:static print:top-auto print:left-auto md:flex print:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center max-w-[1090px] mt-9 rounded-lg bg-white print:mt-0 print:h-64 print:overflow-hidden"
        >
          <!-- Display Line Chart -->
          <div class="h-auto self-center pt-7" id="graphOne">
            <LineChart
              :series-data="store.meterData.totalPerDayChart[data.selectedYear]"
              class="print:scale-[60%] "/>
          </div>

          <!-- Accordion for Additional Information -->
          <appAccordion id="accordion">
            <div class="text-sm">
              <strong>Total Per Day</strong>
              <p>
                This is your total daily energy consumption plotted across a
                12-month period. Useful to assess the energy reduction during
                periods of downtime (planned shutdowns, periods of inactivity),
                does the consumption curve match your expectations? Comparing
                this data to your operational data (things made, customers
                served, revenue generated) provides value insight to your Cost
                of Goods sold and/or Services Provided.
              </p>
            </div>
          </appAccordion>
        </div>

        <div class="flex flex-col mb-12 mt-6" id="desLogo">
          <!-- Company Logo -->
          <img
            src="@/assets/Dark-Insight-Logo.svg"
            class="w-28 h-28 -mb-8"
            id="desLogo1"
          />
        </div>

        <!-- Container for Seasonal Trend Chart and Information -->
        <div
          class="absolute top-[-9999px] left-[-9999px] md:static print:static print:top-auto print:left-auto md:flex print:flex flex-col h-auto overflow-auto mx-auto flex-shrink justify-center max-w-[1090px] mt-9 rounded-lg mb-9 bg-white print:mt-0 print:h-64 print:overflow-hidden"
        >
          <!-- Placeholder for Seasonal Trend Chart -->
          <div class="h-auto self-center pt-7" id="graphSize">
            <!-- Seasonal Trend Chart Component -->
            <SeasonalTrend
              :series-data="
                store.meterData.seasonalTrendChart[data.selectedYear]
              "
            class="print:scale-[60%] "/>
          </div>

          <!-- Accordion for Seasonal Trend Chart Explanation -->
          <appAccordion id="accordion">
            <div class="text-sm">
              <strong>Seasonal Trend Consumption</strong>
              <!-- Detailed Description of Seasonal Trend Chart -->
              <p class="pb-2">
                This graph shows the average time of day energy consumption for
                each season. It is useful for identifying trends and
                understanding variations in energy use across seasons.
              </p>
              <!-- List of Seasons and Their Respective Months -->
              <ul class="text-sm">
                <li><strong>Spring:</strong> March, April, May</li>
                <li><strong>Summer:</strong> June, July, August</li>
                <li><strong>Autumn:</strong> September, October, November</li>
                <li><strong>Winter:</strong> December, January, February</li>
              </ul>
            </div>
          </appAccordion>
        </div>

        <!-- Footer Section -->
        <footer id="footer1" class="hidden text-dbs-light-black print:scale-90">
          <!-- Empty Spacer -->
          <div class="mt-16"></div>
          <!-- Company Logo -->
          <img src="@/assets/DES.png"/>

          <!-- Footer Text Content -->
          <div class="font-montserrat text-3xl pt-10 pl-2">
            Do you have any questions about your insight data or would you like
            in-depth analysis from one of our team members?
          </div>
          <div class="font-montserrat text-3xl pl-2 pt-10">Contact us:</div>
          <div class="font-montserrat text-6xl pl-2 pt-3">0800 031 5470</div>

          <!-- Social Media Links -->
          <div class="flex flex-row justify-center items-center pt-4 pb-2 pl-2">
            <img src="@/assets/LI-Logo.png" class="pr-2" />
            <a
              href="https://www.linkedin.com/company/direct-business-solutions-uk/?originalSubdomain=uk"
              class="pr-6"
              >Direct Business Solutions</a
            >
            <img src="@/assets/LI-Logo.png" class="pr-2" />
            <a
              href="https://www.linkedin.com/company/direct-energy-solutions-uk/"
              class="pr-6"
              >Direct Energy Solutions</a
            >
            <img src="@/assets/LI-Logo.png" class="pr-2" />
            <a href="https://www.linkedin.com/company/direct-trading-solutions/"
              >Direct Trading Solutions</a
            >
          </div>

          <!-- Additional Logo -->
          <img src="@/assets/DBG.png" class="pl-3 pt-4" />
        </footer>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { reactive, computed, onMounted, ref, watch, defineEmits, nextTick } from "vue";
import { DateTime } from "luxon";
import { useAuditStore } from "@/store/index";
import { useRoute } from "vue-router";
import BarChart from "@/components/meter-audit/bar-chart.vue";
import PieChart from "@/components/meter-audit/pie-chart.vue";
import HeatmapMonthTrend from "@/components/meter-audit/heatmap-month-trend.vue";
import HeatmapDayTrend from "@/components/meter-audit/heatmap-day-trend.vue";
import LineAreaChart from "@/components/meter-audit/line-area-chart.vue";
import LineChart from "@/components/meter-audit/line-chart.vue";
import HalfHourTrend from "@/components/meter-audit/half-hour-trend.vue";
import SeasonalTrend from "@/components/meter-audit/seasonal-trend.vue";
import HalfHourTrendMonth from "@/components/meter-audit/half-hour-trend-month.vue";
import router from "@/router";
import appAccordion from "@/components/app-accordion.vue";
import carbonIntensity from "@/components/meter-audit/carbon-intensity.vue";
import yearSelect from "@/components/app-yearselect.vue";
import meterSelect from "@/components/app-meterselect.vue";
import appShareModel from "@/components/app-share-model.vue";

const loadingPhrases = [
  "Getting things ready",
  "Generating graphs",
  "Calculating energy consumption",
  "Measuring carbon impact",
  "Analyzing data points",
  "Compiling environmental statistics",
  "Processing ecological variables",
  "Evaluating sustainability metrics",
  "Optimizing resource allocation",
  "Finalizing analysis report",
  "Synthesizing climate data",
  "Assessing renewable resources",
  "Mapping ecological footprints",
  "Estimating emissions reductions",
  "Aggregating green initiatives"
];

// Define a ref to keep track of the loading phrase
const loadingPhrase = ref(loadingPhrases[0])

// function to change the loading phrase
function animationEnded() {
  let randomIndex;
  let newPhrase;
// do while loop to make sure the new phrase is different from the old one
  do {
    randomIndex = Math.floor(Math.random() * loadingPhrases.length);
    newPhrase = loadingPhrases[randomIndex];
  } while (newPhrase === loadingPhrase.value);
// set the new phrase and increment the animation key
  loadingPhrase.value = newPhrase;
  animationKey.value++;
  animationKey2.value++;
  animationKey3.value++;
}
// Define a ref to keep track of the animation key
const animationKey = ref(0);
const animationKey2 = ref(0);
const animationKey3 = ref(0);

// Define a ref to keep track of the modal's open state
const isModalOpen = ref(false);

// Function to open the modal by setting isModalOpen to true
const openModal = () => {
  isModalOpen.value = true;
};

// Function to close the modal by setting isModalOpen to false
const closeModal = () => {
  isModalOpen.value = false;
};

// Define a ref to keep track of the loading state
const isLoading = ref(false);


// adds a class on print to fix apexcharts legend color
window.onbeforeprint = function() {
  document.querySelectorAll('.apexcharts-legend-marker').forEach(el => {
    el.style.webkitPrintColorAdjust = 'exact';
    el.style.printColorAdjust = 'exact';
  });
};
// removes the class on print to fix apexcharts tooltip
window.onafterprint = function() {
  document.querySelectorAll('.apexcharts-legend-marker').forEach(el => {
    el.style.webkitPrintColorAdjust = '';
    el.style.printColorAdjust = '';
  });
};


/**
 * Fetches a PDF based on selectedYear, zeusId, and meterId.
 * Downloads the PDF once fetched.
 */
 const printToPdf = async () => {
  isLoading.value = true;
      setTimeout( async () => {
        await nextTick();
        window.print();
        isLoading.value = false;
      }, 3000);  
};

// Define reactive data
const data = reactive({
  selectedYear: "Last 12 Month", // Default value for selectedYear

  // Generate an array representing the last 12 months
  last12Months() {
    const now = DateTime.now().startOf("month");
    let i = 0;
    let months = [];
    while (i <= 12) {
      const month = now.minus({ months: i });
      months.push(month);
      i++;
    }
    return months.reverse();
  },
  defaultCategorys: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
});

// /**
//  * Update the selected timeframe to display in the report
//  * @param {string} timeframe - The timeframe to update to
//  */

// Use the audit store.
const store = useAuditStore();

/**
 * Compute categories based on the selected year.
 * If the selected year is not "Last 12 Month", return default categories.
 * Otherwise, return an array of the last 12 months.
 */
const categories = computed(() => {
  if (data.selectedYear !== "Last 12 Month") {
    return data.defaultCategorys;
  } else {
    return data.last12Months().map((month) => month.toFormat("MMM"));
  }
});

// Obtain the current route.
const route = useRoute();

/**
 * Watch for changes in the selected year.
 * Use a timeout to prevent animation stuttering when the year changes.
 */
 const emit = defineEmits(['close', 'Meter list not found', 'timeframe' ]);
watch(
  () => store.selectedYear,
  (newYear) => {
    isLoading.value = true;
    setTimeout(() => {
      updateTimeframe(newYear);
      emit("timeframe");
      isLoading.value = false;
    }, 100);
    
  },
);

/**
 * Update the selected year timeframe.
 */
const updateTimeframe = (timeframe) => {
  data.selectedYear = timeframe;
};

/**
 * On component mount, execute this code block.
 */
onMounted(async () => {
  try {
    // Check the current route name.
    if (route.name === "Meter Audit") {
      // Fetch meter list and shared reports for the user.
      await store.collectMeterListUser();
      await store.collectSharedReports();

      // If no meters are found, throw an error.
      if (!store.meterList || store.meterList.length === 0) {
        router.push({
      name: "not-found",
      params: { pathMatch: route.path.substring(1).split("/") },
    });
        emit("Meter list not found");
        throw new Error("Meter list not found");
        
      }

      // Fetch meter data for the first meter in the list.
      await store.collectMeterDataUser(store.meterList[0].zeusId);
    } else if (route.name === "Meter Audit Share") {
      // Fetch meter list based on word_id in the route params.
      await store.collectMeterList(route.params.word_id);

      // If no meters are found, throw an error.
      if (!store.meterList || store.meterList.length === 0) {
        router.push({
      name: "not-found",
      params: { pathMatch: route.path.substring(1).split("/") },
    });
        throw new Error("Meter list not found");
      }

      // Fetch meter data.
      await store.collectMeterData(
        route.params.word_id,
        store.meterList[0].zeusId,
      );
    } else {
      // If the route is unknown, throw an error.
      router.push({
      name: "not-found",
      params: { pathMatch: route.path.substring(1).split("/") },
    });
      throw new Error("Unknown route");
    }
  } catch (error) {
    // Log the error and redirect to a "not-found" page.
    console.error(error);
    router.push({
      name: "not-found",
      params: { pathMatch: route.path.substring(1).split("/") },
    });
  }
  
});
defineExpose({ openModal, closeModal, isLoading, updateTimeframe, onMounted, emit, store, data, categories})  

</script>

<style scoped>
/* General Styles */

/* For continuous bounce animation */
.bounce-continuous {
  animation: bounceContinuous 1s infinite;
}

/* Keyframes for continuous bouncing */
@keyframes bounceContinuous {
  /* Starting and ending points, where element stays in original position */
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  /* Upward bounce */
  40% {
    transform: translateY(-10px);
  }
  /* Downward bounce */
  60% {
    transform: translateY(-5px);
  }
}

/* Media query for screen: Hide the element with id 'desLogo' */
@media screen {
  #desLogo {
    display: none;
  }
  
}

/* Media query for print */
@media print {
  .chartContainers{
    width: 50mm;
    height: auto;
  }

  .apexcharts-legend-marker {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  /* Ensures that colors are printed accurately */
  
  /* Hide footer and various navigation elements when printing */
  footer,
  #printButton,
  #leftNav,
  #drawer-toggle,
  #welcome,
  #topNav,
  #rightNav,
  #shadow,
  #accordion {
    display: none;
  }
  /* Display specific elements and apply styles during printing */
  #footer1,
  #pdfIntro {
    display: inline-block;
  }
  /* Page breaking rules for graphs */
  #graphOne,
  #pie {
    page-break-after: always;
  }
  /* Center graphTwo and avoid page break after it */
  #graphTwo {
    align-content: center;
    page-break-after: avoid;
  }
  /* Override styles for the full document */
  #fullDoc {
    display: inline-block !important;
    overflow: visible !important;
    height: auto;
    scale: 100%;
  }
  @page {
    size: A4;
    margin: 7mm 7mm 7mm 7mm;
  }
}

/* Vue.js transition classes */
.v-enter-active,
.slide-fade-enter-active {
  transition: opacity 2s ease;
}

.v-enter-to {
  opacity: 1;
}

.v-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}


/* Keyframes for fade-in and fade-out animations */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Transitions for slide fade animations */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
@keyframes fade {
  0%, 25% {
    opacity: 0;
    
  }
  25%, 80% {
    opacity: 1;
    
  }
  100% {
    opacity: 0;
  }
}

.animated-element {
  animation-name: fade;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}
.animated-element2 {
  animation-name: fade-slide;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}
@keyframes fade-slide {
  0% {
    opacity: 0;
    transform: translateY(-60px); /* Start at the original position, but invisible */
  }
  40% {
    opacity: 1;
    transform: translateY(-20px); /* Move up and fade in */
  }
  40%, 70% {
    opacity: 1;
    transform: translateY(25px); /* Move down */
  }
  70%, 90% {
    opacity: 1;
    transform: translateY(0px); /* Move up */
  }
  90% {
    opacity: 1;
    transform: translateY(25px); /* Move down */
  }
  
  100% {
    opacity: 0;
    transform: translateY(-60px); /* Return to original position and fade out */
  }
}





</style>
